import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Type, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  ButtonColor,
  ButtonStyle,
  DynamicComponentBase,
  FieldDto,
  IAction,
  IDynamicComponent,
  ITableLoadEvent,
  ITablePageable,
  OfflineConfig,
  isNullObj,
  markFormGroupTouched,
} from '@shared/classes';
import { DynamicTypeOptionsFormPopupComponent } from '@shared/components/misc/dynamic-type-options-form-popup/dynamic-type-options-form-popup.component';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { ToastService } from '@shared/services/toast.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { sortBy } from 'lodash-es';
import { Table } from 'primeng/table';
import { Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-form-repeater',
  templateUrl: './form-repeater.component.html',
  styleUrls: ['./form-repeater.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: FormRepeaterComponent }],
})
export class FormRepeaterComponent extends DynamicComponentBase implements OnInit {
  @ViewChild('submitButton') submitButtonRef: ElementRef;
  triggerFocus: boolean = false;
  clonedItems: { [s: string]: any } = {};

  @ViewChild('myTable', { static: false }) private docDataTable: Table;

  baseFormDialog: boolean = false;
  tableActions: IAction[] = [
    {
      id: 1,
      icon: 'pi pi-pencil',
      buttonStyle: ButtonStyle.Text,
      color: ButtonColor.Success,
      command: this.onRowEditInit.bind(this),
    },
    {
      id: 2,
      icon: 'pi pi-trash',
      buttonStyle: ButtonStyle.Text,
      color: ButtonColor.Danger,
      command: this.onRowDelete.bind(this),
      passEvent: true,
    },
  ];
  configDialogAction: IAction = {
    id: 3,
    icon: 'pi pi-cog',
    buttonStyle: ButtonStyle.Text,
    color: ButtonColor.Primary,
    command: this.onOpenDynamicTypeOptionsDialog.bind(this),
    // passEvent: true,
    tooltipText: 'Edit Field Options',
  };
  private _cols: IDynamicComponent[] = [];

  get cols() {
    return this._cols;
  }

  @Input() set cols(cols: IDynamicComponent[]) {
    this.setCols(cols);
  }

  @Input() optionsFormPopupComponent: Type<any> = DynamicTypeOptionsFormPopupComponent;
  @Input() title: string = 'Form Repeater';
  @Input() extraEditActions: IAction[] = [];
  @Input() extraActions: IAction[] = [];
  @Input() showOptionsAction: boolean = false;
  @Input() addOrderFieldToOptions: boolean = true;
  @Input() addOrderFieldToObject: boolean = false;
  @Input() addOrderFieldToObjectLabel: string = 'displayOrder';
  @Input() showDeleteButton: boolean = true;
  @Input() showEditButton: boolean = true;
  @Input() showAddForm: boolean = true;
  @Input() showAddFormAsPopup: boolean = false; //disabled for now
  @Input() showReorder: boolean = true;
  @Input() selectionMode: 'single' | 'multiple' | undefined | null = 'multiple';
  @Input() showSelection: boolean = false;
  @Input() flyMode: boolean = false;
  @Input() loading: boolean = false;
  @Input() floatActions: boolean = false;
  @Input() lazyLoadOnInit: boolean = false;
  @Input() popupFormExtraData: any = null;
  @Input() cellsStyle: string = null;
  @Input() tableStyle = null;
  @Input() headerStyle = null;
  @Input() actionHeaderClass = null;
  @Input() actionBodyClass = null;
  @Input() offlineConfig?: OfflineConfig;
  @Input() pageInfo?: ITablePageable;
  @Input() rowsPerPageOptions?: any[];

  @Output() normalMode: EventEmitter<boolean> = new EventEmitter();
  @Output() inlineEdit: EventEmitter<any> = new EventEmitter();
  @Output() filtersChanged: EventEmitter<ITableLoadEvent> = new EventEmitter();
  @Output() lazyLoad: EventEmitter<any> = new EventEmitter();

  selectedItems: any[] = [];
  @Output() onSelectedItemsChange: EventEmitter<any[]> = new EventEmitter();

  @ViewChild('myTable') myTable: Table;
  @Input() set triggerRowEditIndex(triggerRowEditIndex: number) {
    if (!isNullObj(triggerRowEditIndex) || triggerRowEditIndex === 0) {
      setTimeout(() => {
        this.triggerRowEdit(triggerRowEditIndex);
      }, 20);
    }
  }
  baseFormGroup: FormGroup;
  private formValid = new Subject<boolean>();
  formValid$ = this.formValid.asObservable();
  submitButtonAction: IAction = {
    id: 1,
    buttonStyle: 'raised',
    label: 'Add Item',
    buttonType: 'submit',
    command: this.onAddForm.bind(this),
    passEvent: true,
    icon: 'pi pi-plus',
  };
  showAddFormPopupAction: IAction = {
    id: 2,
    buttonStyle: 'raised',
    label: 'Add Item',
    buttonType: 'button',
    command: this.showAddFormPopup.bind(this),
    passEvent: true,
    icon: 'pi pi-plus',
  };
  tableItems: FormRepeaterRow[] = [];
  uiChange: boolean = false;

  constructor(
    private toastService: ToastService,
    private appDialogService: AppDialogService,
    public viewModeService: ViewModeService
  ) {
    super();
  }

  ngAfterViewInit() {
    if (this.flyMode) {
      this.tableItems.forEach((row) => {
        this.docDataTable.initRowEdit(row);
      });
    }
  }

  ngOnInit() {
    if (this.control?.value?.length > 0) {
      this.tableItems = [];
      const changes = this.control?.value;
      if (Array.isArray(changes)) {
        changes.forEach((item) => {
          // let newForm = cloneDeep(this.baseFormGroup);
          let newForm = new FormGroup<any>({});
          Object.entries(this.baseFormGroup.controls).forEach(([key, control]) => {
            newForm.addControl(key, new FormControl(null, control.validator, control.asyncValidator));
          });
          newForm.patchValue({ ...item });

          if (this.flyMode) {
            newForm.valueChanges.subscribe((res) => {
              this.updateControl();
            });
          }

          this.tableItems.push({
            form: newForm,
            id: newForm?.value?.options?.ORDER ?? this.tableItems.length.toString(),
            fields: this.getDynamicFields(newForm),
          });
        });
      } else {
        Object.keys(changes as { [x: string]: FieldDto })?.forEach((key) => {
          const item = changes[key] as FieldDto;
          // let newForm = cloneDeep(this.baseFormGroup);
          let newForm = new FormGroup<any>({});
          Object.entries(this.baseFormGroup.controls).forEach(([key, control]) => {
            newForm.addControl(key, new FormControl(null, control.validator, control.asyncValidator));
          });
          newForm.patchValue({ ...item });

          if (this.flyMode) {
            newForm.valueChanges.subscribe((res) => {
              this.updateControl();
            });
          }

          this.tableItems.push({
            form: newForm,
            id: newForm?.value?.options?.ORDER ?? this.tableItems.length.toString(),
            fields: this.getDynamicFields(newForm),
          });
        });
      }
    }
    this.subs.sink = this.control.valueChanges.subscribe((changes: FieldDto[]) => {
      if (!this.uiChange) {
        this.tableItems = [];
        if (isNullObj(changes)) changes = [];
        if (Array.isArray(changes)) {
          changes.forEach((item) => {
            // let newForm = cloneDeep(this.baseFormGroup);
            let newForm = new FormGroup<any>({});
            Object.entries(this.baseFormGroup.controls).forEach(([key, control]) => {
              newForm.addControl(key, new FormControl(null, control.validator, control.asyncValidator));
            });
            newForm.patchValue({ ...item });

            this.tableItems.push({
              form: newForm,
              id: newForm?.value?.options?.ORDER ?? this.tableItems.length.toString(),
              fields: this.getDynamicFields(newForm),
            });
          });
        } else {
          Object.keys(changes as { [x: string]: FieldDto })?.forEach((key) => {
            const item = changes[key] as FieldDto;
            // let newForm = cloneDeep(this.baseFormGroup);
            let newForm = new FormGroup<any>({});
            Object.entries(this.baseFormGroup.controls).forEach(([key, control]) => {
              newForm.addControl(key, new FormControl(null, control.validator, control.asyncValidator));
            });
            newForm.patchValue({ ...item });
            this.tableItems.push({
              form: newForm,
              id: newForm?.value?.options?.ORDER ?? this.tableItems.length.toString(),
              fields: this.getDynamicFields(newForm),
            });
          });
        }

        if (this.showOptionsAction) this.tableItems = sortBy(this.tableItems, 'id');

        this.baseFormGroup.reset();
        this.selectedItems = [];
        this.onSelectionChange(null);
      }
      this.uiChange = false;
    });
  }
  setInputOptions() {
    this.title = this.inputOptions?.formRepeater?.title ?? this.title;
    this.setCols(this.inputOptions?.formRepeater?.cols ?? this.cols);
    this.extraActions = this.inputOptions?.formRepeater?.extraActions ?? this.extraActions;
    this.extraEditActions = this.inputOptions?.formRepeater?.extraEditActions ?? this.extraEditActions;
  }
  setCols(cols: IDynamicComponent[]) {
    this._cols = cols;
    this.baseFormGroup = new FormGroup({});
    this.subs.sink = this.baseFormGroup.statusChanges.subscribe((formState) => {
      this.formValid.next(this.baseFormGroup.valid);
      setTimeout(() => {
        this.formValid.next(this.baseFormGroup.valid);
      }, 50);
      setTimeout(() => {
        this.submitButtonRef?.nativeElement?.focus();
      }, 1000);
    });

    cols.forEach((field) => {
      this.baseFormGroup.addControl(field.options.name, field.options.control);
    });
  }
  onRowEditInit(item: FormRepeaterRow) {
    this.normalMode.next(false);

    // this.clonedItems[item.id] = cloneDeep(item);
    let newForm = new FormGroup<any>({});
    Object.entries(item.form.controls).forEach(([key, control]) => {
      newForm.addControl(key, new FormControl(control.getRawValue(), control.validator, control.asyncValidator));
    });
    // newForm.patchValue(item.form.getRawValue());
    this.clonedItems[item.id] = {
      form: newForm,
      data: { ...item.data },
      fields: [...item.fields],
      id: item.id,
    };
  }
  onRowDelete(event: { event: any; data: number }) {
    this.appDialogService.confirmPopup(
      {
        accept: () => {
          this.tableItems.splice(event.data, 1);
          this.updateControl();
        },
      },
      event.event.target
    );
  }

  onRowEditSave(item: FormRepeaterRow, event) {
    if (item.id >= 0 && item.form.valid) {
      const uuidField = item?.fields?.find((x) => x?.options?.localUuidField);
      if (uuidField) {
        item?.form?.controls?.UUID?.patchValue(uuidv4());
      }
      this.normalMode.next(true);
      delete this.clonedItems[item.id];
      // this.toastService.success(MessageKeys.success, MessageKeys.editedSuccessfully)
      this.updateControl();
      this.inlineEdit.emit(item?.form?.value);
    } else {
      event?.stopPropagation();
      event?.preventDefault();
      this.toastService.warn('Validation Error', 'Please Fill Form Correctly');
    }
  }

  onRowEditCancel(item: any, index: number) {
    this.normalMode.next(true);
    this.tableItems[index] = this.clonedItems[item.id];
    delete this.clonedItems[item.id];
  }
  showAddFormPopup() {
    this.baseFormDialog = true;
  }
  onAddForm(event) {
    event?.preventDefault();
    event?.stopPropagation();
    if (this.baseFormGroup.valid) {
      let newForm = new FormGroup<any>({}); //cloneDeep(this.baseFormGroup);
      this.cols.forEach((field) => {
        const val = field?.options?.localUuidField
          ? uuidv4()
          : field.options.formatGetValue
            ? field.options.formatGetValue(field.options.control)
            : field.options.control.getRawValue();
        newForm.addControl(
          field.options.name,
          new FormControl(val, field.options.control.validator, field.options.control.asyncValidator)
        );
      });
      if (this.showOptionsAction && this.addOrderFieldToOptions)
        newForm?.controls?.options?.patchValue({ ORDER: this.tableItems.length });
      this.tableItems.push({
        form: newForm,
        id: this.tableItems.length.toString(),
        fields: this.getDynamicFields(newForm),
      });
      this.updateControl();
      this.baseFormGroup.reset();
      this.baseFormDialog = false;
    } else {
      // this.toastService.error(MessageKeys.errorHappened, MessageKeys.invalidAction)
      this.toastService.warn('Validation Error', 'Please Fill Form Correctly');
      markFormGroupTouched(this.baseFormGroup);
    }
  }
  getDynamicFields(form: FormGroup): IDynamicComponent[] {
    return this.cols.map((field) => {
      return this.getDynamicField(form, field);
    });
  }
  getDynamicField(form: FormGroup, field: IDynamicComponent): IDynamicComponent {
    return {
      componentType: field.componentType,
      options: {
        ...field.options,
        name: field.options.name + '_i_' + this.tableItems.length.toString(),
        control: form.controls[field.options.name],
        data: field.options.passFormGroupAsData ? form.value : field.options.data,
        inputOptions: {
          ...field?.options?.inputOptions,
          linkedControl: field?.options?.inputOptions?.linkedControlName
            ? (form?.controls[field?.options?.inputOptions?.linkedControlName] as FormControl)
            : null,
          linkedControlValue: field?.options?.inputOptions?.linkedControlName
            ? form?.controls[field?.options?.inputOptions?.linkedControlName]?.value
            : null,
          dropDownInput: {
            ...field?.options?.inputOptions?.dropDownInput,
            appendTo: 'body',
          },
          dynamicTypeOptionsInput: {
            fieldTypeControl: field?.options?.inputOptions?.linkedControlName
              ? (form?.controls[field?.options?.inputOptions?.linkedControlName] as FormControl)
              : null,
            fieldType: field?.options?.inputOptions?.linkedControlName
              ? form?.controls[field?.options?.inputOptions?.linkedControlName]?.value
              : null,
          },
        },
        instanceInFormRepeater: true,
      },
      viewModeOnly: field.viewModeOnly,
    };
  }
  updateControl() {
    let values = this.tableItems.map((item, index) => {
      if (this.showOptionsAction) {
        return {
          ...item.form.getRawValue(),
          options: this.addOrderFieldToOptions
            ? { ...item?.form?.getRawValue()?.options, ORDER: index }
            : { ...item?.form?.getRawValue()?.options },
        };
      } else {
        if (this.addOrderFieldToObject) {
          return {
            ...item.form.getRawValue(),
            [this.addOrderFieldToObjectLabel]: index,
          };
        } else {
          return {
            ...item.form.getRawValue(),
          };
        }
      }
    });
    this.uiChange = true;
    this.control.patchValue(values, { emitEvent: true });
    this.onChanges.emit(values);
  }
  onOpenDynamicTypeOptionsDialog(options: { item: FormRepeaterRow; index: number }) {
    if (this.popupFormExtraData?.impactValueOptions) {
      this.popupFormExtraData.impactValueOptions = this.popupFormExtraData?.impactValueOptions?.map((x) => {
        let currentTimeStamp = new Date().getTime();
        return { ...x, code: x?.code ?? `IMV-${currentTimeStamp}` };
      });
    }
    this.appDialogService.showDialog(
      this.optionsFormPopupComponent,
      'Field Configurations',
      (e) => {
        if (e && options.item.id >= 0 && options.item.form.valid) {
          // delete this.clonedItems[item.id];

          let patchVal = {
            //@WARNING this expects options to be a map not an array.
            ...e,
            options: this.addOrderFieldToOptions ? { ...e.options, ORDER: options.index } : { ...e.options },
          };
          this.tableItems[options.index].form.patchValue(patchVal);
          // this.tableItems[options.index].form.controls.options.patchValue(this.addOrderFieldToOptions ? {...e.options,ORDER:options.index} : {...e.options});
          // this.tableItems[options.index].form.controls.type.patchValue(e.type);
          // options.item.form.patchValue(e);
          // this.toastService.success(MessageKeys.success, MessageKeys.editedSuccessfully);
          this.updateControl();
        }
      },
      {
        data: { ...this.tableItems[options?.index]?.form?.value, ...this.popupFormExtraData, showSaveAndClose: false },
      }
    );
  }

  onRowSelect(event) {
    // this.onSelectedItemsChange.emit(this.selectedItems);
  }

  onRowUnselect(event) {
    // this.onSelectedItemsChange.emit(this.selectedItems);
  }

  onSelectAllChange(event) {}

  triggerRowEdit(rowIndex: number) {
    // Get the row data from the table data
    const rowData = this.myTable.value[rowIndex];

    // Open the row for editing
    // this.myTable.editingCell = { rowIndex, field: '' };
    this.myTable.initRowEdit(rowData);
    // this.myTable.value[rowIndex].editingRow = rowData;
  }

  onSelectionChange(event) {
    this.onSelectedItemsChange.emit(this.selectedItems);
  }

  onLazyLoad(event) {
    this.lazyLoad.emit(event);
  }

  onFiltersChanged(event) {
    this.filtersChanged.emit(event);
  }
}
export interface FormRepeaterRow {
  form: FormGroup;
  data?: any;
  fields: IDynamicComponent[];
  id: any;
}
